@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); /* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body { height: 100%; }
*:not(.mat-icon, .material-icons) {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
body {
  margin: 0;
}
.green-snackbar {
  background: #ECFDF5 !important;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.green-snackbar .texto-snackbar {
  color: #065F46;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}
.green-snackbar .icone-snackbar {
  color: #34D399;
}
.green-snackbar .icone-fechar-snackbar {
  color: #10B981;
}
.svg-success {
  stroke: #34D399;
}
.svg-danger {
  stroke:#DC2626;
}
.div-icone-modal {
  width: 40px;
  height: 40px;
  border-radius: 24px;
  display: flex;
  margin-right: 16px;
}
.div-icone-success {
  background: #D3F1E0;
}
.div-icone-danger {
  background: #FEE2E2;
}
.mat-success {
  background: #34D399;
  color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.mat-danger {
  background: #DC2626;
  color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.red-snackbar {
  background: #FEF2F2 !important;
}
.red-snackbar .texto-snackbar {
  color: #991B1B;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
}
.red-snackbar .icone-snackbar {
  color: #F87171;
}
.red-snackbar .icone-fechar-snackbar {
  color: #EB5757;
}
.texto-cinza {
  color: #6B7280;
}
.container-acoes-lote {
  padding: 2rem;
}
.imagem-acoes-lote {
  background-image: url("/assets/images/uploading.gif");
  background-repeat:no-repeat;
  background-position: center center;
  background-size: cover;
}
.imagem-login {
  background-image: url("./assets/images/notas-sistema.jpg");
  background-repeat:no-repeat;
  background-position: center center;
  background-size: cover;
}

.container-acoes-lote, .imagem-acoes-lote {
  height: 333px;
  text-align: center;
  position: relative;
}
.div-texto-upload, .container-acoes-lote, .imagem-acoes-lote {
  margin: 0 auto;
  max-width: 100%;
  width: 401px;
}
.div-texto-upload {
  margin-top: 47px;
}

.titulo-pagina {
  font-weight: 500 !important;
  font-size: 26px !important;
  line-height: 48px !important;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 13px;
  max-height: 42px;
  background: #FFFFFF;
  border: 1px solid #D1D5DB;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px !important;
}
.select-sem-label .mat-form-field-infix {
  padding-bottom: 19px !important;
}
.select-sem-label .mat-select-value {
  padding-bottom: 15px
}
.botao-icone {
  width: 56px;
  height: 42px;
}
.small-size {
  font-size: 10px;
}
.size-12 {
  font-size: 12px !important;
}

.font-weight-bolder {
  font-weight: 700!important;
}
.card-login-body {
  vertical-align: center;
  padding: 0;
}
.card-login-header {
  border-radius: 0.75rem 0.75rem 0 0;
  min-height: 250px;
}
.avatar-login {
  margin-bottom: 25.5px;
}
.input-formulario-login {
  padding: 9px 13px;
  max-width: 100%;
  width: 368px;
  height: 42px;

  background: #FFFFFF;

  border: 1px solid #D1D5DB !important;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.grupo-input-formulario label {
  padding-bottom: 4px;
}




.texto-esqueceu-senha {
  padding-block: 24px;
}
.text-recuperacao-senha {
  font-weight: 700;
  size: 18px;
}

.card-login {
  height: auto !important;
  margin: auto;
  border: none;
  box-shadow: unset !important;
  padding: 60px !important;
}
.mat-form-field-invalid .mat-form-field-flex {
  border: 2px solid #F1637D;
}
.mat-dialog-container {
  width: 512px !important;
  max-width: 100%;
  min-height: 194px;
  height: auto!important;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px !important;
}
.mat-header-cell.mat-column-status, .mat-cell.mat-column-status {
  max-width: 100px !important;
}
.mat-header-cell.mat-column-opcoes, .mat-cell.mat-column-opcoes {
  max-width: 150px !important;
}



.mat-card-table-mobile {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0 !important;
}
.bt-modal-100 {
  width: 100% !important;
  padding-bottom: 24px;
}
.bt-modal-100 .mat-button-wrapper{
  justify-content: center !important;
}
.bt-modal-100 .mat-button-wrapper > * {
  margin-inline: 5px;
}
.input-mobile .mat-form-field-wrapper {
  padding-bottom: 24px;
}
.card-mobile .mat-card {
  margin-bottom: 15px;
}

button label {
  cursor: pointer;
}
.mat-button-disabled label {
  cursor: default !important;
}
.titulo-card-selecao {
  color: #1D3C85;
}
b {
  font-weight: bold !important;
}
.bg-certificado-expirado {
  background-color: #F1637D !important;
}
.color-secondary {
  color: #F1637D;
}
.bg-certificado-valido, .bg-dashboard {
  background-color: #1D3C85 !important;
}
.bg-disabled-300 {
  background-color: #B0B0B0;
}
.header-topo, .logo-sidebar {
  min-height: 71px;
}
.mat-form-field.tamanho-padrao{
  width: 254px;
}
.mat-form-field.tamanho-medio {
  width: 230px;
}
.mat-form-field.tamanho-padrao, .mat-form-field.tamanho-medio {
  max-width: 100%;
}
.bt-form {
  font-size: 14px;
  height: 42px;
  margin-bottom: 18px;
  margin-top: 0;
}
.botao-tamanho-limitado {
  max-width: 45px;
}
.bt-cadastro {
  width: auto;
  height: 42px;
  display: flex !important;
  color:white;
  background-color: #1D3C85;
}
.bt-cadastro .mat-button-wrapper {
  display: flex;
  align-self: center;
  justify-content: space-between;
  width: 100%;
}
.bt-cadastro .afastamento-esquerdo {
   margin-left: 10px !important;
 }

.bt-cadastro.justify-content-center .mat-button-wrapper {
  justify-content: center !important;
}
.bt-cadastro label {
  margin-block: auto;
  margin-right: 16px;
}
.select-paginator .mat-form-field-wrapper, .pb-0 .mat-form-field-wrapper {
  padding-bottom: 0 !important;
  margin: auto;
}
/*.mat-focused .mat-form-field-wrapper {*/
/*  border: 2px solid #1D3C85;*/
/*  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);*/
/*  border-radius: 6px;*/
/*}*/
.div-paginator {
  padding-top: 28px;
}
.rounded {
  border-radius: 10px !important;
}
.select-expandido {
  max-height: unset !important;
}
.mat-form-field {
  width: 100%;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.6);
}
a {
  cursor: pointer;
  text-decoration: none !important;
}
.cursor-default {
  cursor: default !important;
}
.padding-right {
  padding-right: 10px;
}
.nav-link {
  display: flex;
}

.nav-link.disabled {
  color: #6c757d !important;
}
.mat-fab.mat-warn {
  color: #f44336;
}
.mat-fab.mat-success {
  color: #1ea97c;
}

.card [class*=card-header-] .card-icon, .card [class*=card-header-] .card-text {
  border-radius: 3px;
  background-color: #3f51b5;
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
}
.card-stats .card-header.card-header-icon {
  text-align: right;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.no-margin {
  margin: 0;
}
.mat-row .mat-cell:before, .mat-header-cell {
  margin: 5px;
}
.mat-card-subtitle {
  margin-top: 10px;
}
.mat-row .mat-cell {
  font-size: 14px;
  margin: 5px;
  word-break: break-word;
}
.mat-tab-label {
  font-size: 14px;
}
.invalid-feedback{
  display: block;
}
.min-vh-100 {
  min-height: 100%;
}
.max-vw-100 {
  max-width: 100%;
}
.card, mat-card {
  height: 100%;
}

.card-header, mat-card-header {
  height: 30%;
  border-bottom: none;
}
.page-header {
  display: flex;
}

.badge, .btn {
  text-transform: uppercase;
}
.badge {
  --bs-badge-padding-x: 0.9em;
  --bs-badge-padding-y: 0.55em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.45rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius,0);
}
.btn-rounded {
  border-radius: 1.875rem !important;
}
.btn-icon-only {
  width: 1.5rem;
  height: 1.5rem;
}
.mat-fab .mat-button-wrapper {
  display: unset !important;
}
.mat-fab {
  width: 45px !important;
  height: 45px !important;
  box-shadow: none !important;
  background: transparent !important;
}
.card-border {
  box-shadow: unset;
}

.modal-loading {
  z-index: 2000 !important;
}
.modal-form {
  z-index: 1000;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.modal-footer {
  padding-bottom: 15px;
  padding-top: 0;
}
.container-fluid .row > div .pb{
  padding-bottom: 30px;
}

.mat-table td, .mat-table th {
  width: 25%;
}

.margin-auto {
  margin: auto;
}

.mat-menu-item a {
  text-decoration: none;
}
.red {
  color: #cc3366 !important;
}

.button-user {
  width:100%;
  font-size: 15px;
  text-align: end !important;
}
.button-user span {
  margin-right: 5px;
}
.login {
  height: 100%;
}
.main-login {
  padding: 0;
}
.form-signin .form-floating:focus-within {
  z-index: 2;
}
div.cdk-overlay-connected-position-bounding-box {
  align-items: flex-end !important;
}
.total-loading, .modal-loading {
  height: 100%;
}
.modal-dialog {
  margin: auto;
  max-width: 700px;
  display: flex;
  align-items: center;
  height: 100%;
}
.total-loading {
  margin: 0 !important;
  max-width: 100% !important;
}
.celula-checkbox {
  max-width: 54px;
}
.no-pointer {
  cursor: unset;
}
.icone-menu {
  text-align: center;
}
.searchcontainer {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.searchcontainer .search-part {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bg-gradient-success {
  background-image: linear-gradient(195deg,#66bb6a,#43a047);
}

.bg-gradient-disabled {
  background-image: linear-gradient(195deg,#747b8a,#495361);
}
.mat-tab-body-wrapper {
  margin-top: 10px;
}
.botao-arquivo {
  height: 38px !important;
}
.mat-tab-body-content {
  height: unset !important;
}
.div-chave {
  padding-bottom: 0 !important;
}
.div-chave, .text-forgot, .color-gray {
  color: gray;
}
.manifestacao-pendente {
  padding-bottom: 1.34375em;
}
.card-relatorio {
  margin-bottom: 10px;
}
.padding-top-25 {
  padding-top: 25px;
}
.display-none, .mat-form-field-underline {
  display: none;
}
.mat-paginator-container .mat-select-value {
  width: auto;
}
.mat-paginator-container .mat-select-trigger {
  justify-content: center;
}
.mat-paginator-range-label {
  margin: 0 !important;
}
.mat-paginator-page-size-select {
  width: 40px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.card-login-header .mat-card-header-text {
  display: none !important;
}
.text-forgot {
  cursor: pointer;
  padding-top: 5px;
}
.shadow {
  box-shadow: none !important;
}
.div-page {
  padding-bottom: 0 !important;
  min-height: 91vh!important;
}
.modal-header {
  display: block;
  padding: 0;
}
.btn-close {
  margin-top: 10px !important;
}
/*.card-header-primary .card-icon, .bg-gradient-primary, .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb  {*/
/*  background: linear-gradient(60deg, #26c6da, #00acc1);*/
/*}*/
.page-header, .background-customizado, .modal-header, body, .mat-tab-label {
  background-color: #F5F5F5 !important;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar,
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, .38);
}

.background-white, mat-card-header, .navbar-light, .sidebar  {
  background-color: white !important;
}
.card-icon .card-title, .color-white, .card [class*=card-header-] .material-icons,
.mat-simple-snackbar .mat-button-wrapper, .green-snackbar, .red-snackbar, .white{
  color: white;
}
.mat-header-row, .label-chaves,
.mat-row .mat-cell:before, .mat-cell, .card-category, .modal-title, .mat-menu-item a {
  color: #5F5F5F !important;
}
.color-black {
  color: black !important;
}
.mat-fab.mat-primary, .color-primary {
  color: #1D3C85;
}
.mat-raised-button.mat-primary {
  background-color: #1D3C85;
}
.filial-background {
  background-color: #6478e8 !important;
}
.mar-header-cell {
  color: gray;
}
.matriz-background {
  height: 46px;
  width: 46px;
  border-radius: 5px;
}
.navbar-toggler { z-index: 1000}
.modal-body .row > * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.modal-body .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.modal-title {
  margin: 0 !important;
}
.div-modal-title, .modal-body, .modal-footer {
  padding-top: 30px;
  padding-bottom: 30px;
}
button {
  height: 45px;
}
.cdk-overlay-pane {
  padding-right: 1rem;
}
.nav-item-principal {
  padding-bottom: 20px;
}
.icone-lg {
  transform: scale(1);
}
.icone-sm {
  transform: scale(0.8);
}
.icone-seta {
  padding-top: 3px;
}
.mat-radio-button {
  margin-inline: 30px;
}

nav.sidebar {
  overflow-y: auto;
}
* {
  scrollbar-width: thin;
  scrollbar-color: #747b8a;
}

/* Works on Chrome, Edge, and Safari */
nav::-webkit-scrollbar {
  width: 4px;
}

nav::-webkit-scrollbar-thumb {
  background-color: #747b8a;
  border-radius: 20px;
}
.card-dashboard {
  min-height: 100px;
}
.logo-sidebar {
  height: 64px;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #D1D5DB;
}
.card-selecao-empresa {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.div-icone {
  width: 78px;
  min-width: 78px;
  padding-left: 8px;
}





/*REFORMULACAO TAILWIND*/

.bg-pink-300 {
  background-color: #F1637D;
}
.flex-principal {
  display: flex !important;
}
















/* RESPONSIVO */
@media (min-width: 1100px) {
  .sidebar-div.col-lg-2, .sidebar.col-lg-2 {
    width: 300px !important;
  }
}

@media screen and (max-width: 960px) {
  nav.sidebar, nav.sidebar > *, .sidebar-div > *, .sidebar-div{
    display: none !important;
    z-index: 300 !important;
  }
  nav.sidebar.show, nav.sidebar.show > *{
    display: block !important;
  }
  .navbar-toggler {
    display: block !important;
  }
  .mat-table {
    border: 0;
    vertical-align: middle
  }

  .mat-table caption {
    font-size: 1em;
  }

  .mat-table .mat-header-cell {
    border: 10px solid;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  mat-cell:first-of-type {
    padding-left: 0 !important;
  }

  .mat-table td, .mat-table {
    width: 100% !important;
  }

  .mat-table .mat-row {
    border-bottom: 5px solid #ddd;
    display: block;
  }
  .mat-header-row {
    display: none;
  }
  .mat-footer-row, .mat-header-row, .mat-row {
    padding: 0 24px;
  }

  mat-cell:last-of-type {
    padding-right: 0;
  }
  .texto-selecao-empresa {
    display: none;
  }
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
    margin-bottom: 4%;
  }
  .mat-table .mat-cell:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: normal;

    font-size: .85em;
  }
  .mat-table .mat-cell:last-child {
    border-bottom: 0;
  }
  .mat-table .mat-cell:first-child {
    margin-top: 4%;
  }
  .celula-checkbox {
    max-width: unset !important;
  }
  .notas-importadas, .notas-erros, .notas-atencao, .notas-retornar {
    font-size: 14px !important;
  }
  .notas-atencao {
    padding-top: 9px;
  }
  .visualizacao-mobile {
    display: block;
  }
  .visualizacao-normal {
    display: none !important;
  }
  .modal-dialog {
    max-width: 90% !important;
  }
  .card-login-header {
    padding-bottom: 0 !important;
    min-height: 120px !important;
  }
}
@media (min-width: 960px) {
  .visualizacao-mobile {
    display: none !important;
  }
  .no-flex-normal {
    flex-direction: unset !important;
  }
}

@media (max-width: 767px){
  .navbar-selecao-empresa {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .sidebar-div.col-md-3, .sidebar.col-md-3 {
    width: 250px;
  }
  .modal-footer, .div-modal-title, .modal-body {
    padding-right: 90px;
    padding-left: 90px;
  }
  .main-login {
    max-width: 495px;
  }
}

@media screen and (max-width: 500px) {
  mat-form-field.input-filtro-chave, mat-form-field.input-filtro-data {
    width: 100% !important;
  }
}
